import { Routes, Route } from "react-router-dom";
import Layout from './Layout';

// Containers
import Home from './containers/Home';

// Styling
import './App.css';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="*" element={<Home />} />
      </Route>
    </Routes>
  );
}

export default App;
